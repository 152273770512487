import autoTable from "jspdf-autotable";
import { printSRFSConfigData, printSRFSConfiguration } from "./SRFSConstant";
import {
  customFontFamily,
  dynamicHeader,
  footerConfig,
  headerConfig,
  tableConfigurationheaderandfooter,
} from "./srfsConfig";


const certificateHeader = "/images/certificate_header.png";
const certificateFooter = "/images/certificate_footer.png";


export const drawLetterHeadHeaderImg = () => {
  let headerImage = certificateHeader;
  try {
    if (printSRFSConfigData.letterHeadAndElectronicAndDraftConfig?.letterHead) {
      const scaledWidth = printSRFSConfiguration.pageWidth;
      const scaledHeight = headerConfig.lettterImgHeight;
      printSRFSConfiguration.pdfDoc.addImage(
        headerImage,
        "PNG",
        0,
        0,
        scaledWidth,
        scaledHeight
      );
    }
  } catch (error) {
    console.error("Error drawing header image:", error);
  }
};
export const drawLetterFooterImg = () => {
  try {
    if (printSRFSConfigData.letterHeadAndElectronicAndDraftConfig?.letterHead && footerConfig?.footerimgRemove) {
      const scaledWidth = printSRFSConfiguration.pageWidth;
      // Draw the image on the page
      printSRFSConfiguration.pdfDoc.addImage(
        certificateFooter,
        "PNG",
        0,
        printSRFSConfiguration.pageHeight - footerConfig.footerimgHeight + 2,
        scaledWidth,
        footerConfig.footerimgHeight
      );
    }
  } catch (error) {
    console.error("Error drawing footer image:", error);
  }
};

export const drawDynamicHeader = () => {
  let localY = headerConfig?.lettterImgHeight + 5;

  const panels = dynamicHeader(
    printSRFSConfigData.SRFSDetailsConfig
  );
  let tableRows = [];
  for (let i = 0; i < panels.leftPanel.keys.length; i++) {
    tableRows.push([
      {
        content: panels.leftPanel.keys[i],
        styles: { halign: "left", font: customFontFamily?.boldFont },
      },
      { content: panels.leftPanel.values[i], styles: { halign: "left" } },
      {
        content: panels.rightPanel.keys[i],
        styles: { halign: "left", font: customFontFamily?.boldFont },
      },
      { content: panels.rightPanel.values[i], styles: { halign: "left" } },
    ]);
  }
  // Draw the table using autoTable
  autoTable(printSRFSConfiguration.pdfDoc, {
    startY: localY,
    margin: tableConfigurationheaderandfooter?.margins,
    body: tableRows,
    theme: "grid",
    headStyles: {
      fillColor: false,
      textColor: printSRFSConfiguration.textColor,
      fontSize: headerConfig.tableRowfontSizes,
      font: customFontFamily?.boldFont,
    },
    styles: {
      fillColor: false,
      textColor: printSRFSConfiguration.textColor,
      lineColor: printSRFSConfiguration.textColor,
      lineWidth: printSRFSConfiguration.lineWidth,
      fontSize: headerConfig.tableRowfontSizes,
      halign: "center",
      cellPadding: headerConfig.cellPadding,
      font: customFontFamily?.normalFont,
    },
  });
};

export const drawDynamicFooter = () => {
  // if(!printSRFSConfigData.IsCSRF) return;
  let srfsSetting =
    printSRFSConfigData.letterHeadAndElectronicAndDraftConfig;

  let Row1 = ["Prepared By", "Approved By"];
  let Row2 = [
    printSRFSConfigData.SRFSDetailsConfig.userId?.createdBy,
    printSRFSConfigData.SRFSDetailsConfig.userId?.approvedBy,
  ];
  let Row3 = [
    printSRFSConfigData.SRFSDetailsConfig.userImg?.createdByImg,
    printSRFSConfigData.SRFSDetailsConfig.userImg?.approvedByImg,
  ];

  const tableRows = [];
  // Add Row1 to tableRows
  tableRows.push(
    Row1.map((content) => ({
      content,
      styles: {
        font: customFontFamily?.boldFont,
        fontStyle: customFontFamily?.bold,
        halign: "center",
      },
    }))
  );

  // Add Row2 to tableRows
  tableRows.push(
    Row2.map((content) => ({
      content,
      styles: {
        font: customFontFamily?.normalFont,
        halign: "center",
      },
    }))
  );

  // Add Row3 with images (if present)
  if (srfsSetting?.signImg) {
    tableRows.push(
      Row3.map((imgSrc) => ({
        content: "", // Content remains empty; images will be added later
        imgSrc, // Pass the image source for use in didDrawCell
        styles: {
          font: customFontFamily?.normalFont,
          halign: "center",
          minCellHeight: 25,
        },
      }))
    );
  }

  let formattedTableRows = tableRows.map((row, rowIndex) =>
    row.map((column, columnIndex) => ({
      ...column, // Keep all other properties of the column
      styles: {
        ...column.styles, // Preserve existing styles
        halign:
          columnIndex === 0 // First column
            ? "left"
            : columnIndex === row.length - 1 // Last column
            ? "right"
            : "center", // Default alignment for other cases
      },
    }))
  );

  // column width prepare
  const numberOfColumns = 2;
  const totalWidth =
    printSRFSConfiguration.pdfDoc.internal.pageSize.getWidth() - 29;
  const columnWidth = totalWidth / numberOfColumns;

  // Generate table with autoTable
  autoTable(printSRFSConfiguration.pdfDoc, {
    startY:
      printSRFSConfiguration.pageHeight - footerConfig.footerimgHeight - footerConfig.footerandbodygap -25,
    margin: tableConfigurationheaderandfooter?.margins,
    body: formattedTableRows,
    theme: "grid",
    styles: {
      fillColor: false,
      textColor: printSRFSConfiguration.textColor,
      lineColor: printSRFSConfiguration.textColor,
      lineWidth: printSRFSConfiguration.lineWidth,
      fontSize: printSRFSConfiguration.fontSizes.h2,
      halign: "center",
      font: customFontFamily?.normalFont,
      cellPadding:srfsSetting?.signImg ? { top: 2, left: 5, bottom: 2, right: 5 } : { top: 5, left: 5, bottom: 5, right: 5 },
    },
    columnStyles: {
      // Set equal width for each column
      0: { cellWidth: columnWidth },
      1: { cellWidth: columnWidth },
    },
    didDrawCell: (data) => {
      const { section } = data;
      if (section === "body" && srfsSetting?.signImg) {
        const cell = data.cell;
        const rowIndex = data.row.index;
        const columnIndex = data.column.index;
        // Check if this cell corresponds to the imageRow
        if (rowIndex === tableRows.length - 1) {
          const imgSrc = Row3[columnIndex];
          if (imgSrc) {
            const imgWidth = 60;
            const imgHeight = cell.height - 10;
            const x = cell.x + 5;
            const y = cell.y + 5;
            // Add the image to the PDF
            printSRFSConfiguration.pdfDoc.addImage(
              imgSrc,
              "PNG",
              x,
              y,
              imgWidth,
              imgHeight
            );
          }
        }
      }
    },
  });
};
